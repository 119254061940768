import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import './Live.scss';
import axios from 'axios';
import {
  IonApp,
  IonIcon
} from '@ionic/react';

import {
  Container,

  Navbar,
  Nav,
  NavDropdown,
  FormControl,
  Form,
  Button,
  Col,
  Row,
  Alert
} from 'react-bootstrap'

const LivePresentation = (props) => {

  const [token, setToken] = useState(null)
  const [validParticipant, setValidParticipant] = useState(true)
  const [sentQuestion, setSentQuestion] = useState(false)
  const [init, setInit] = useState(true)
  const [player, setPlayer] = useState(null)


  useEffect(() => {
    const checkin = async () => {

      let query = queryString.parse(props.location.search)
      setToken(query.token)

      if (player && token) {
        try {

          let headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",

          }

          let data = await axios.post("https://otass-backend.azurewebsites.net/participants/checkin", {}, { headers })

          console.log(`Axios response: `, data)
          //setValidParticipant(true)
        } catch (err) {
          console.debug(`[LivePresentation]`, player)
          player.destroy()
          console.log(`API Error: `, err)
          setValidParticipant(false)
        }
      }
    }
    checkin()

  }, [player])



  let query = queryString.parse(props.location.search)
  //setToken(query.token)

  console.log(`[URL Parameter Token] Token:`, query)
  if (!query.token) return <Redirect to="/error" />
  if (!validParticipant) return <Redirect to="/error" />

  let navStyle = {
    paddingTop: "5px",
    paddingBottom: "5px",
    height: "70px"
  }



  return (
    <div className="App">

      <Navbar bg="light" expand="lg" style={navStyle}>
        <Navbar.Brand href=""> {/*<span style={{ fontSize: "20px" }}>PRIMER ENCUENTRO VIRTUAL: Aguas Todas (Live)</span>*/}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

      </Navbar>

      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={7} lg={8} xl={8}><VideoPlayer setPlayer={setPlayer}></VideoPlayer></Col>
          <Col xs={12} sm={12} md={5} lg={4} xl={4}><StreamingForm setSentQuestion={setSentQuestion} token={token} /></Col>
        </Row>

      </Container>
      <footer className="page-footer font-small blue pt-4"></footer>

    </div>
  )
}



const VideoPlayer = ({ setPlayer }) => {

  //console.debug("Parent objects: ",props.setPlayer)

  const [init, setInit] = useState(true)
  useEffect(() => {

    let player = window.$wp.create("playerElement",
      /*{
        "license": "PTRL1-mkACG-K3ttT-v74XR-6djew-DAJMR",
        "sources": [{
          "sourceURL": "https://cdn3.wowza.com/1/VVdaOHh5VkJPaHVE/TjVqaVhx/hls/live/playlist.m3u8"
        },
        {
          "sourceURL": ""
        }],
        "title": "Primer Encuentro Virtual Aguas Todas",
        "description": "",
        "autoPlay": true,
        "mute": false,
        "volume": 75,
        "posterFrameURL": "//prod-railsapp.s3.amazonaws.com/uploads/player/video_poster_image/1170987/logo_aguas_todas_regional-01.png"
      }*/
      {
        "license": "PLAY1-h9MCf-vhhfa-kdaQ4-nnH6R-b9mPf",
        "sources": [{
          "sourceURL": "https://wowzaprod189-i.akamaihd.net/hls/live/736667/3aa420c7/playlist.m3u8"
        },
        {
          "sourceURL": ""
        }],
        "title": "Jornada online Aguas Limpias",
        "description": "",
        "autoPlay": true,
        "mute": false,
        "volume": 75,
        "posterFrameURL": "https://eventos-alterlatina.s3.amazonaws.com/otass/BannerAguasLimpias.png"
      }
    );
    console.debug(`[VideoPlayer]`, player)
    setPlayer(player)
  }, [init]);

  let playerStyle = {
    width: "100%",
    height: "0",
    padding: "0 0 56.25% 0"
  }
  return (

    <div id="playerElement" style={playerStyle}></div>
  )
}

const StreamingForm = ({ token }) => {




  const [sentQuestion, setSentQuestion] = useState(false)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [question, setQuestion] = useState('')

  useEffect(() => {
    console.info("[StreamingForm] Re-building form component")

  }, [sentQuestion])

  let margins = {
    marginTop: "20px"
  }



  const sendMessage = async () => {


    try {

      let headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",

      }

      let requestBody = { fullName, email, question }
      let data = await axios.post("https://otass-backend.azurewebsites.net/participants/askQuestion", requestBody, { headers })

      console.log(`Axios response: `, data)
      setSentQuestion(true)
    } catch (err) {
      console.debug(`[LivePresentation] ${err}`,)
      setSentQuestion(true)
    }

  }

  return (
    sentQuestion ?
      <div>
        <Alert variant="success" onClose={() => { }} dismissible>
          <Alert.Heading>Gracias</Alert.Heading>
          <p>
            Enviamos tu consulta de manera exitosa.
      </p>
        </Alert>
        <ion-button color="buttonBody"
          onClick={() => { setSentQuestion(false); setFullName(''); setEmail(''); setQuestion(''); }}>Enviar otra pregunta</ion-button>
      </div>

      :
      <Form>
        <h1>Haz tus preguntas</h1>
        <span>Ingresa tus datos personales y luego ingresa tu pregunta</span>
        <Form.Control size="lg" type="text" placeholder="Nombres y apellidos" style={margins} value={fullName} onChange={v => setFullName(v.target.value)} />
        <Form.Control size="lg" type="text" placeholder="Correo electrónico" style={margins} value={email} onChange={v => setEmail(v.target.value)} />
        <Form.Control size="lg" as="textarea" maxLength={200} rows={7} placeholder="Tu pregunta" style={margins} value={question} onChange={v => setQuestion(v.target.value)} />
        <ion-button color="buttonBody" onClick={() => { sendMessage() }}>Enviar</ion-button>
      </Form>
  )
}

export default LivePresentation