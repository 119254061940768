import React from 'react';
import logo from './logo.svg';
import Live from './pages/live'
import Error from './pages/error'
import axios from 'axios'
import {} from 'react-router'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link} from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
const {useState} = React



const App = () => {
  const [cards,setCards] = useState([])
  

  return (
    <Router>
      <Switch>
        <Route exact={true} path="/" component={Live}>
        </Route>
        <Route path="/error">
          <Error/>
        </Route>
      </Switch>
    </Router>
  )
}



export default App;
