import React, { useState, useEffect } from 'react';
import './Error.scss'


const Error = (props)=>{

    return (
        <div className="error" style={{width:"100%",backgroundImage:`url(/Assets/bg1.jpg)`}}>
            <div className="container">
            <h1 className="number">404</h1>
					<p className="description">
						OOPS! Something went wrong here
					</p>
            </div>
        </div>
    )
}

export default Error